// Width of the content area
// $content-width:		850px !default;
$content-width: 85% !default;
$blog-content-width: 60% !default;

$on-phone: 			450px !default;
$on-palm:       600px !default;
$on-laptop:			800px !default;

$base-font-size:   1.1rem !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;
$blog-line-height: 1.75em;
$blog-p-margin:    1.75em;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
// Notice the following mixin uses max-width, in a deprecated, desktop-first
// approach, whereas media queries used elsewhere now use min-width.
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

// @mixin relative-font-size($ratio) {
//   font-size: $base-font-size * $ratio;
// }

/**
 * Wrappers
 */
.post-wrapper {
  width: $blog-content-width;
  margin: auto;
}

/********************/
/****  Home Page ****/
/********************/


/********************/
/***  About Page ****/
/********************/
.about-page {
  width: 55%;
  margin: auto;
  margin-top: 30px;

  .about-top {
    display: flex;
    justify-content: space-between;

    .about-pic {
      height: 475px;
      width: auto;
      border-radius: 3px;
    }

    .about-top-text {
      max-width: 375px;
    }
  }
}

@include media-query(1600px) {
  .about-page {
    width: 65%;
  }
}

@include media-query(1385px) {
  .about-page {
    width: 75%;
  }
}

@include media-query(1000px) {
  .about-page {
    width: 100%;
  }
}

@include media-query($on-laptop) {
  .about-page {
    width: 100%;

    .about-top {
      flex-direction: column;
      align-items: center;
      margin-bottom: 0px;

      .about-top-pic {
        margin-bottom: 1.75em;
      }
      
      .about-top-text {
        margin-top: 0px;
        max-width: none;
      }
    }
  }
}

@include media-query($on-phone) {
  .about-page {
    .about-top {
      .about-top-pic {
        img {
          max-width: 100%;
          height: auto;
        }
      }

    }
  }
}

/********************/
/*****  Posts  ******/
/********************/
article.post {
	line-height: 1.75em;
}

.post-content a {
	color: rgb(74, 198, 255);
  text-decoration: none;
}

.post-content a:hover {
	color: rgb(109, 210, 255);
  text-decoration: underline;
}

.blog-post-header .bar {
  border-bottom: 4px #111 solid;
  margin: 50px 0;
  max-width: 170px;
}

.post-header-image {
	margin-top: 0;
	padding: 260px 0;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.center-image {
	margin: 0 auto;
	display: block;
}

.blog-video {
	width: 75%;
	height: 475px;
}

/* Social-Share */

.social-share-floating-bar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.social-share-floating-bar a {
  display: block;
  transition: all 0.3s ease;
  padding: 11px;
  color: #fff;
  font-size: 20px;
  text-align: center;
}

.social-share-btns-container {
  font-size: 14px;
  text-align: center;
}

.social-share-btns {
  display: inline-block;
}

.social-share-btns .share-btn {
  margin: 0 5px;
  padding: 4px 8px;
  color: #fff;
}

.social-share-btns .share-btn i {
  display: inline-block;
  margin-right: 5px;
}

.share-btn {
  background-color: #95a5a6;
}

.share-btn:hover, .share-btn:active, .share-btn:visited {
  background-color: #798d8f;
  text-decoration: none;
}

.share-btn-twitter {
  background-color: #00aced;
}

.share-btn-twitter:hover, .share-btn-twitter:active, .share-btn-twitter:visited {
  background-color: #0087ba;
}

.share-btn-facebook {
  background-color: #3b5998;
}

.share-btn-facebook:hover, .share-btn-facebook:active, .share-btn-facebook:visited {
  background-color: #2d4373;
}

.share-btn-linkedin {
  background-color: #007bb6;
}

.share-btn-linkedin:hover, .share-btn-linkedin:active, .share-btn-linkedin:visited {
  background-color: #005983;
}

@include media-query($on-laptop) {
  .blog-video {
    width: 100%;
    height: 255px;
  }
  .social-share-floating-bar {
    display: none;
  }
}

@include media-query($on-palm) {
  .post-wrapper{
    width: 85%;
  }
  .blog-video {
		height: 275px;
	}
	.header-image {
		padding: 115px 0;
  }
}

@include media-query($on-phone) {
  .blog-video {
		height: 195px;
  }
}

.post-categories {
  position: absolute;
  bottom: 0;
}

.post-date,
.post-categories {
	font-size: 14px;
}

.post .category-link {
	color: #828282;
  text-decoration: none;
}

.post .category-link:visited,
.post .category-link:hover {
  text-decoration: underline;
}

.blog-icon {
	margin-right: 5px;
}


/********************/
/*****  Footer  *****/
/********************/

.social-links {
	margin-bottom: 30px;
}
